import React from "react";
import TokenInLayout from "../[id]";
import { graphql } from "gatsby";

const YVLayout = ({data,id}) => {
    return <TokenInLayout data={data} id={id} yv={true} />
}
export default YVLayout

export const query = graphql`
  query InYVQuery {
    site {
      siteMetadata {
        siteUrl
        serverUrl
        title
        description
      }
    }
  }
`